<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="d-flex align-center justify-space-between">
          <div>
            <h2>Payment History (14)</h2>
            <span class="text--disabled">See history of your payment plan</span>
          </div>

          <v-btn color="blue-grey" class="ma-2 white--text">
            <v-icon class="ma-2" right dark> mdi-download </v-icon>
            Download All
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12">
        <v-card flat outlined>
          <v-data-table
            hide-default-footer
            :single-select="singleSelect"
            item-key="name"
            show-select
            :headers="headers"
            :items="payments"
            :loading="loading"
          >

            <!-- eslint-disable-next-line -->
            <template v-slot:item.invoice="{ item }">
              <h4 class="font-weight-bold">{{ item?.invoice }}</h4>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.status="{ item }">
              <div v-if="item.status == 'Success'" class="d-flex">
                <v-icon color="success" x-small left>mdi-circle</v-icon>
                <span class="font-weight-medium">Success</span>
              </div>

              <div v-else-if="item.status == 'Pending'" class="d-flex">
                <v-icon color="orange" x-small left>mdi-circle</v-icon>
                <span class="font-weight-medium">Pending</span>
              </div>

              <div v-else-if="item.status == 'Failed'" class="d-flex">
                <v-icon color="error" x-small left>mdi-circle</v-icon>
                <span class="font-weight-medium">Failed</span>
              </div>
            </template>

            <!-- action -->
            <template #[`item.action`]="{ item }">
              <v-btn
                class="btn"
                text
                small
                color="primary"
                elevation="0"
                @click.stop="createCustomer"
              >
                <v-icon left dark> mdi-download </v-icon> Download
                {{ item?.tet }}</v-btn
              >
            </template> </v-data-table
          ><v-divider></v-divider> </v-card
      ></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "Payment Invoice",
          value: "invoice",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Date",
          value: "date",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "",
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
      size: ["10", "20", "50"],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        itemsPerPage: 10,
      },
      formStatus: false,
      mode: "new",
      customer: null,
      loading: false,
      confirmDeleteCustomerDialog: false,

      payments: [
        {
          invoice: "Invoice#0034 - Sep 2023",
          amount: "$1,200",
          date: "Sep 23, 2023",
          status: "Success",
        },
        {
          invoice: "Invoice#0035 - Oct 2023",
          amount: "$1,500",
          date: "Oct 15, 2023",
          status: "Failed",
        },
        {
          invoice: "Invoice#0036 - Nov 2023",
          amount: "$900",
          date: "Nov 12, 2023",
          status: "Pending",
        },
        {
          invoice: "Invoice#0037 - Dec 2023",
          amount: "$2,000",
          date: "Dec 20, 2023",
          status: "Success",
        },
        {
          invoice: "Invoice#0038 - Jan 2024",
          amount: "$800",
          date: "Jan 5, 2024",
          status: "Failed",
        },
        {
          invoice: "Invoice#0039 - Feb 2024",
          amount: "$1,100",
          date: "Feb 18, 2024",
          status: "Success",
        },
        {
          invoice: "Invoice#0040 - Mar 2024",
          amount: "$1,300",
          date: "Mar 14, 2024",
          status: "Pending",
        },
        {
          invoice: "Invoice#0041 - Apr 2024",
          amount: "$1,700",
          date: "Apr 25, 2024",
          status: "Success",
        },
        {
          invoice: "Invoice#0042 - May 2024",
          amount: "$1,000",
          date: "May 10, 2024",
          status: "Failed",
        },
        {
          invoice: "Invoice#0043 - Jun 2024",
          amount: "$1,400",
          date: "Jun 8, 2024",
          status: "Success",
        },
      ],
    }
  },
}
</script>

<style scoped>
.btn {
  text-transform: unset !important;
  font-size: 15px !important;
}
</style>
